<template>
  <div class="quv-cont">
    
    <h2 class="uuid">{{uuid}}</h2>
    <div class="tips">
      <p>拍摄视频规范：</p>
      <p>1.1  被保手机边框全部纳入拍摄范围，无明显反光；</p>
      <p>1.2 进入拨号界面；</p>
      <p>1.3 按*#06#调出手机IMEI号；</p>
      <p>1.4 拍摄手机后盖，停留2秒</p>
    </div>
    
    <div class="ecode ignore-ecode">
      <div v-if="videoSrc">
        <video  class="video" :src="videoSrc" controls="controls"></video>
      </div>

      <div v-else class="ecode-un" @click="clickFile">
        <monitor theme="two-tone" size="24" :fill="['#333' ,'#ff9d0e']"/>
        <div>
          开始拍摄
        </div>
      </div>
    </div>

    <input ref="file" style="display: none;" name="file" type="file" accept="video/*" capture="camcorder" @change="updateFile" />


    <div class="fiex-cont" v-show="videoSrc">
      <div class="btn" @click="clickFile">重拍</div>
      <div class="form-sub" @click="upFile">上传</div>
    </div>


    <div class="modo" v-if="show">
      <div class="modo-in">
        <div class="modo-icon">
          <img src="@/assets/img/success.png" alt="">
        </div>
        <div class="modo-name">上传完成</div>
        <div class="modo-title">
          请用被拍摄手机返回验机页面点击页面”下一步”按钮 
        </div>
        <div>
          <div class="modo-btn2" @click="goLink">我也要保障</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { Monitor} from '@icon-park/vue'

export default {
  components: {
    Monitor
  },
  data () {
    return {
      type: this.$route.query.type ? this.$route.query.type : 1,
      uuid: this.$route.query.uuid,
      query: {...this.$route.query},
      videoSrc: '',
      show: false,
      num: 0, // 验证码倒计时
      form: {
        mobile: '',
        code: '',
      },
    }
  },
  mounted () {
    
  },
  methods: {
    goLink () {
      if(this.type == 1){
        // 事后 - 跳转微店
        window.location.href = this.$init.buyLink
      }else{
        // 事前 - 跳转到选择页面
        this.$router.push('/quv/home/2')
      }
    },
    /**
     * 选取文件
     */
    clickFile () {
      this.$refs.file.click()
    },
    updateFile (e) {
      const file = e.target.files[0] || e.dataTransfer.files[0]
      let URL = window.URL || window.webkitURL; // 获取当前域名地址
      this.videoSrc = URL.createObjectURL(file);   // 拼接 URL + file 并转成 blob地址
    },
    /**
     * 文件上传
     */
    upFile (){
      const vm = this;
      if(! this.videoSrc){
        vm.$createToast({
          txt: '请上传视频',
          type: 'warn'
        }).show()
        return
      }
      var file = this.$refs.file.files[0];
      vm.$store.commit('updateLoader', true);
      var formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("uuid", this.uuid);
      vm.$axios({
        method: 'post',
        url:  vm.$init.apis.default + "/upload/video",
        data: formData,
        timeout: 600000,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function(response) {
        vm.$store.commit('updateLoader', false);
        if (response.data.header.code == "A1000") {
          vm.show = true;
        } else {
          vm.$createToast({
            txt: response.data.header.msg,
            type: 'warn'
          }).show()
        }
      }).catch(function(err) {
        vm.$store.commit('updateLoader', false);
        vm.$createToast({
          txt: '网络错误',
          type: 'error'
        }).show()
      });
    },
    
  },
}
</script>

<style lang="scss" scoped>
.quv-cont{
  min-height: 100.1vh;
  background-color: #0c945a;
  box-sizing: border-box;
  padding-top: 80px;
}

.form-cont{
  width: 700px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.04);
  padding: 36px;
  // padding-bottom: 115px;
  position: relative;
}
.form-item{
  display: flex;
  margin-bottom: 22px;
  border-bottom: 1px solid #F4F4F4;
  .icon{
    padding-top: 30px;
    margin-right: 20px;
    img{
      display: block;
      height: 42px;
    }
  }
  .input{
    flex: 10;
    padding: 0;
    input{
      display: block;
      width: 100%;
      padding: 22px 0;
      font-size: 32px;
      line-height: 60px;
      outline: none;
    }
  }
  .btn{
    padding: 22px 0;
    margin-left: 20px;
  }
  .button{
    width: 140px;
    height: 60px;
    font-size: 24px;
    text-align: center;
    line-height: 60px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #DCDCDC;
  }
}
.form-sub{
  width: 410px;
  height: 100px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 100px;
  color: #fff;
  margin: 0 auto;
  border: 4px solid #fff;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.04);
}

.ecode{
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ignore-ecode{
  min-height: 300px;
}

.tips{
  padding: 60px 0;
  width: 600px;
  margin: 0 auto;
  color: #eee;
  font-size: 24px;
  line-height: 42px;
  p{
    // font-weight: 700;
    margin-bottom: 10px;
    // text-indent: 20px
  }
  a{
    font-size: 28px;
    color: #ff9d0e;
    font-weight: 700;
    text-align: center;
  }
}

.ecode-un{
  height: 200px;
  width: 200px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 6px;
  color: #333;
  padding-top: 40px;
  div{
    padding: 20px;
    color: #333;
  }
}
.uuid{
  text-align: center;
  color: #333;
  font-weight: 700;
}

.video{
  height: 400px;
  width: 400px;
}
.fiex-cont{
  display: flex;
  align-items:center;
  justify-content:center;
  box-sizing: border-box;
  padding: 40px;
}
.btn{
  margin-top: 40px;
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  background: #fff;
  color: #1F955B;
  text-align: center;
}
</style>
